.show-more-button {
  font-family: 'Mulish';
  padding: 7px 16px;
  width: 100%;
  max-width: 664px;
  border-radius: 12px;
  border: 1px solid #726e38;
  background-color: #fff;
  cursor: pointer;
  transition: opacity 0.2s linear;
  justify-self: center;
  color: #21211b;
  font-size: 24px;
  line-height: 1.33;
  align-self: center;
}

.show-more-button:hover {
  opacity: 0.7;
}
