/* .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
} */

.header {
  display: grid;
  grid-template-columns: 1fr 2.2fr 1fr;
  align-items: center;
  padding: 28px 40px;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e9d7c1;
  background-color: rgba(256, 256, 256, 1);
  z-index: 1000;
}

.header__link {
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid rgba(216, 165, 102, 1);
  border-radius: 12px;
  padding: 11px 15px 11px 15px;
  cursor: pointer;
  text-decoration: none;
  background-color: rgba(216, 165, 102, 1);
  transition: background-color 0.6s;
}

.header__link:hover {
  border: 1px solid rgba(114, 110, 56, 1);
  background-color: rgba(164, 158, 74, 1);
}

.header__func {
  display: flex;
  align-items: center;
  max-width: 623px;
  width: 100%;
  gap: 32px;
}

.header__image {
  max-width: 24px;
  stroke: rgba(255, 254, 246, 1);
  fill: green;
}

.header__text {
  font-family: 'Mulish', Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.33;
  color: rgba(255, 254, 246, 1);
}
