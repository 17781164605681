@font-face {
  font-family: 'Didact-Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/DidactGothic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Mulish-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Mulish-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Mulish-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Mulish-SemiBold.ttf') format('truetype');
}
