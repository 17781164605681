.category__list-button {
  font-family: 'Mulish';
  color: #726e38;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 12px;
  border: 1px solid #726e38;
  background-color: #fff;
  padding: 12px 20px;
  cursor: pointer;
  transition:
    background-color 0.6s,
    color 0.3s;
}

.category__list-button:hover,
.category__list-button_active {
  color: #fff;
  background-color: #a49e4a;
  border: none;
  padding: 13px 21px;
}

.category__list-button:active {
  color: #fff;
  background-color: #726e38;
  border: none;
  padding: 13px 21px;
}
