.up-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 170px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: url('../../images/top-to-scroll-button.svg') 100% / auto auto
    no-repeat;
  visibility: hidden;
  transition: background 0.6s;
  z-index: 1500;
}

.up-btn_visible {
  visibility: visible;
}

.up-btn:hover {
  background: url('../../images/top-to-scroll-button-hover.svg') 100% / auto
    auto no-repeat;
}
