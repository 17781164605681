.to-catalog__button {
  margin-top: 40px;
  width: 316px;
  font-family: 'Mulish', 'Arial', sans-serif;
  color: rgba(64, 63, 50, 1);
  font-size: 16px;
  line-height: 1.5;
  border-radius: 12px;
  border: 1px solid rgba(164, 158, 74, 1);
  background-color: #fff;
  padding: 11px 16px;
  cursor: pointer;
  transition: background-color 0.6s;
  text-decoration: none;
  text-align: center;
}

.to-catalog__button:focus,
.to-catalog__button:hover,
.to-catalog__button:active {
  background-color: #e3e1b9;
}
