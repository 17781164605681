/* .infopage {
  display: flex;
  margin: 0 auto;
  width: calc(100% - 2 * 40px);
  padding-top: 66px;
} */

.infopage {
  display: flex;
  margin: 0 auto;
  width: calc(100% - 2 * 40px);
  padding: 40px 0 100px 0;
}

.infopage__content {
  display: flex;
  flex-direction: column;
  max-width: 896px;
}

.infopage__navpanel {
  display: flex;
  max-width: 407px;
  width: 100%;
  margin: 44px 57px 0 0;
}

.infopage__content-title {
  font-family: 'Didact-Gothic', Arial, sans-serif;
  margin: 0;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -0.5px;
  color: #403f32;
}
