.card-section-with-title {
  box-sizing: border-box;
}

.card-section-with-title_style_main {
  padding: 100px 40px 0;
}

.card-section-with-title_style_search {
  padding: 40px 40px 100px 40px;
}

.card-section-with-title_style_catalog {
  padding: 0;
}

.card-section-with-title__title {
  font-family: 'Didact-Gothic';
  font-size: 40px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.5px;
  margin: 0;
  color: #21211b;
}

.card-section-with-title__search-title {
  font-size: 24px;
  line-height: 28px;
  color: #21211b;
}

.card-section-with-title__header {
  display: flex;
  margin-bottom: 40px;
}

.card-section-with-title__header-title {
  margin-bottom: 20px;
}

.card-section-with-title__span {
  font-weight: 700;
  line-height: 32px;
}
