.app {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: 'Mulish', Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  max-width: 1440px;
  min-height: 100vh;
}

input:-webkit-autofill {
  -webkit-text-fill-color: rgba(0, 0, 0, 1);
  transition: all 5000s ease-in-out;
}

input:focus::-webkit-textfield-decoration-container {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.selectable-link {
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.selectable-link:hover {
  opacity: 0.6;
}

.selectable-button {
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.selectable-button:hover {
  opacity: 0.8;
}

.main {
  flex: 1 1 auto;
}
