.search-form__wrapper {
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 100%;
  left: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 15, 15, 0.5);
}

.search-form__wrapper_disactive {
  position: static;
  background-color: transparent;
}

.search-form__wrapper_minor {
  display: flex;
  position: relative;
  margin: 0 auto;
  width: 1440px;
}

.search-form__wrapper_minor_disactive {
  position: static;
  width: 458px;
}

.search-form {
  border-radius: 12px;
  background-color: #fff;
  max-width: 458px;
  padding: 12px 12px 16px;
  width: 100%;
  top: 19px;
  left: 36%;
  position: absolute;
}

.search-form_disactive {
  position: static;
  padding: 0;
  max-height: 46px;
}

.search-form__form {
  max-width: 458px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: flex;
  background-color: #a49e4a;
  max-height: 52px;
}

.search-form_active {
  background-color: #726e38;
}

.search-form__input-field {
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  border: 2px solid #a49e4a;
  border-radius: 16px;
  max-width: 400px;
  height: 100%;
  width: 100%;
}

.search-form__input-field_active {
  border: 2px solid #726e38;
}

.search-form__input {
  font-family: 'Mulish';
  box-sizing: border-box;
  margin: 9px 0 9px 5px;
  width: calc(100% - 5px);
  max-width: 400px;
  max-height: 24px;
  border: none;
  outline: none;
  color: #21211b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.search-form__button-field {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  background-color: #a49e4a;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.search-form__button-field_active {
  background-color: #726e38;
}

.search-form__button {
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: inherit;
  background-image: url('../../images/search.svg');
  background-size: contain;
  min-width: 24px;
  min-height: 24px;
}

.search-form__button_type_close {
  position: absolute;
  top: 14px;
  right: 11px;
  min-width: 14px;
  min-height: 14px;
  background-image: url('../../images/close-button_theme_pale.svg');
  background-color: transparent;
  background-size: cover;
}

.search-form__results {
  padding: 0;
  margin: 0;
  margin-top: 12px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.search-form__result {
  display: flex;
  gap: 4px;
}

.search-form__result-button {
  background-image: url('../../images/search-list.svg');
  background-color: transparent;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: none;
  padding: 0;
}

.search-form__result-text {
  width: 100%;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #21211b;
  padding-bottom: 4px;
  border-bottom: 1px solid #726e38;
}
