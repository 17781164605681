.recipient {
  display: flex;
  width: 690px;
  flex-basis: auto;
  flex-direction: column;
  border-radius: 6px;
  background: var(--White, #fff);
  margin: 0px 0 0 0px;
}

.recipient__deliveryadress {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 19px;
  height: 144px;
}

.recipient__title {
  color: var(--black-900, #121212);
  /* Button/Large */
  font-family: Mulish, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.recipient__recipient {
  display: flex;
  flex-direction: column;
  height: 232px;
}

.recipient__form {
  display: flex;
  flex-direction: column;
  max-width: 664px;
  margin: 0 24px 0 0px;
}

.recipient__field {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  column-gap: 17px;
  row-gap: 16px;
  max-width: 664px;
  padding-top: 19px;
}

.recipient__input {
  width: -webkit-fill-available;
  height: 70px;
  display: flex;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(233, 215, 193, 1);
  background: var(--White, #fff);
  margin: 0px 0 0 0;
  padding: 0 0 0 16px;
  outline: 0;
  color: var(--text-black, #21211b);
  /* Body S */
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.71;
}

.recipient__input:focus {
  border: 1px solid rgba(227, 225, 185, 1);
}

.recipient__input_comment {
  height: 92px;
}

.recipient__input_error {
  color: rgba(183, 65, 40, 1);
}

.recipient__input_error:focus {
  border: 1px solid rgba(183, 65, 40, 1);
}

.recipient__comment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0;
}

.recipient__field-container {
  position: relative;
}

.recipient__field-placeholder {
  display: block;
  position: absolute;
  left: 16px;
  top: 20px;
  color: rgb(159, 159, 159);
  font-family: Mulish, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.71;
  font-size: 18px;
  pointer-events: none;
  transform-origin: left;
  transition:
    top 0.3s,
    transform 0.3s,
    color 0.3s;
}

.recipient__field-placeholder_error {
  color: rgba(183, 65, 40, 1);
}

.recipient__input:focus + .recipient__field-placeholder,
.recipient__input:not(:focus):valid + .recipient__field-placeholder {
  top: -8px;
  left: 16px;
  transform: scale(0.8);
  /* Caption */
  font-family: Mulish, Arial, sans-serif;
  font-weight: 400;
  line-height: 2;
}

.recipient__button {
  margin: 41px auto 12px auto;
  border-radius: 12px;
  padding: 12px 16px;
  width: 100%;
  min-height: 56px;
  font-family: Mulish, Arial, sans-serif;
  font-size: 24px;
  line-height: 1.33;
  font-style: normal;
  font-weight: 400;
  border: 1px solid rgba(167, 167, 167, 1);
  background-color: rgba(164, 158, 74, 1);
  color: rgba(251, 250, 235, 1);
  transition: background-color 0.6s;
}

.recipient__button:hover {
  border: 1px solid rgba(114, 110, 56, 1);
  background-color: rgba(114, 110, 56, 1);
  color: rgba(251, 250, 235, 1);
}

.recipient__button:disabled {
  cursor: inherit;
  border: 1px solid rgba(205, 205, 198, 1);
  background-color: rgba(250, 250, 250, 1);
  color: rgba(205, 205, 198, 1);
}

.recipient__politic {
  margin: 0px 24px 24px 24px;
  color: #000;
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  align-self: center;
}

.recipient__politic-text {
  text-decoration: none;
  color: #000;
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}

.recipient__form-error {
  color: rgba(83, 105, 65, 1);
  font-weight: 400;
  line-height: 0.9;
  min-height: 25px;
  padding-top: 4px;
  font-family: Mulish, Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
  min-height: 24px;
}
