.search__text {
  margin: 0;
  color: #21211b;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.search__link {
  color: #21211b;
}
