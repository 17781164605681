.footer {
  padding: 62px 40px 32px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  background-color: #fffef6;
  column-gap: 24px;
  justify-items: start;
  align-items: center;
}

.footer__logo {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
}

.footer__menu {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.footer__menu:last-child {
  margin-right: 10px;
}

.footer__menu_style_contacts {
  row-gap: 16px;
}

.footer__menu-title {
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #21211b;
}

.footer__link {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43;
  text-decoration: none;
  cursor: pointer;
  color: #403f32;
  gap: 20px;
  transition: color 0.5s ease;
}

.footer__link:hover {
  color: #726e38;
}

.footer__email {
  color: #403f32;
}

.footer__copyright {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #a5a38f;
}

.footer__attribute {
  margin: 0;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 100%;
  color: #cdcdc6;
}

.footer__link_type_site {
  text-decoration: underline;
  color: inherit;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}
