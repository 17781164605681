.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.not-found__title {
  width: max-content;
  margin: 0;
  margin-bottom: 12px;
  font-family: 'Didact-Gothic';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: -0.5px;
  color: #21211b;
}

.not-found__text {
  width: max-content;
  margin: 0;
  margin-bottom: 40px;
  font-family: 'Didact-Gothic';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.not-found__link {
  border-radius: 12px;
  padding: 12px 16px;
  font-size: 16px;
  background-color: #a49e4a;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #fbfaeb;
}
