.thanksfororder {
  display: flex;
  flex-direction: column;
  margin: 20px 0 100px 40px;
  justify-content: space-between;
}

.thanksfororder__title {
  font-family: Didact-Gothic, Arial, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.5px;
  margin: 20px 0 0 0;
}

.thanksfororder__confirm {
  font-family: Mulish, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  margin: 0;
  margin: 32px 0px 19px;
}

.thanksfororder__text {
  max-width: 664px;
  color: #000;
  font-family: Mulish, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.thanksfororder__text + .thanksfororder__text {
  margin-top: 16px;
}

.thanksfororder__intro {
  display: flex;
  flex-direction: column;
}

.thanksfororder__link {
  margin-top: 40px;
  display: flex;
  padding: 12px 16px 12px 16px;
  max-width: fit-content;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(114, 110, 56, 1);
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.6s;
  font-family: Mulish, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(33, 33, 27, 1);
  padding: 16px 16px;
}

.thanksfororder__link:hover {
  background-color: rgba(227, 225, 185, 1);
}
