.about-project {
  display: flex;
  background-image: url(../../images/about-project.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 40px 0;
  width: 100%;
  min-height: 508px;
}

.about-project__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 567px;
  padding: 24px;
  margin-top: 140px;
  margin-bottom: 140px;
  margin-left: 40px;
  background-color: rgba(250, 249, 240, 0.5);
  border-radius: 12px;
}

.about-project__title {
  color: #21211b;
  margin: 0;
  font-family: Didact-Gothic, Arial, sans-serif;
  min-width: 567px;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.5px;
  text-align: center;
}

.about-project__container-button {
  margin: 28px auto 0 auto;
}
