.sociallink {
  display: flex;
}

.sociallink__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  column-gap: 16px;
}

/* .sociallink__item+.sociallink__item {
  padding-left: 30px;
} */
