.favourites-section {
  margin: 42px 40px 100px 40px;
}

.favourites-empty__container {
  margin: 0 auto;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 896px;
}

.favourites-empty__text {
  margin: 0;
  font-family: 'Didact-Gothic', 'Arial', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.17;
  text-align: center;
}
