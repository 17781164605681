.product-page {
  padding: 16px 40px 100px;
}

.product-page__link_type_catalog {
  display: flex;
  width: 228px;
  padding-top: 20px;
  gap: 12px;
  color: #403f32;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 24px;
}

.product-page__link-text {
  transition: color 0.5s ease;
  color: #403f32;
}

.product-page__link_type_catalog:hover .product-page__link-text {
  color: #726e38;
}

.product-page__main {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  padding-top: 21px;
}

.product-page__sliders {
  display: flex;
  width: 41%;
  max-width: 548px;
  gap: 26px;
}

.product-page__main-slider {
  overflow: hidden;
  width: 100%;
}

.product-page__nav-slider {
  width: 100%;
  height: calc(100% - 4px);
}

.product-page__main-image {
  max-width: 408px;
  width: 100%;
  border-radius: 12px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.pruduct-page__nav-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 21.15%;
  gap: 30px;
}

.product-page__nav-image {
  aspect-ratio: 1/1;
  object-fit: cover;
  display: block;
  border-radius: 12px;
}

.product-page__nav-image_active {
  border: 1px solid #726e38;
}

.product-page__button {
  cursor: pointer;
}

.product-page__info {
  max-width: 780px;
  width: 58%;
}

.product-page__brand {
  margin: 0;
  margin-bottom: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #21211b;
}

.product-page__name {
  margin: 0;
  margin-bottom: 32px;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  color: #21211b;
}

.product-page__subtitle {
  margin: 0;
  margin-bottom: 16px;
  border-bottom: 1px solid #e9d7c1;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.product-page__description {
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.product-page__price {
  margin: 0;
  margin-bottom: 39px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #21211b;
}

.product-page__string {
  display: grid;
  grid-template-columns: auto auto 3fr;
  justify-items: flex-end;
  gap: 20px;
  margin-bottom: 79px;
}

.product-page__counter {
  box-sizing: border-box;
  max-width: 115px;
  border-radius: 12px;
  border: 1px solid #a49e4a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 12px;
  max-height: 48px;
}

.product-page__button {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}

.product-page__button_type_plus {
  background-image: url('../../images/Add.svg');
  width: 24px;
  height: 24px;
  transition: background-image 0.5s ease;
}

.product-page__button_type_plus:hover {
  background-image: url('../../images/plus-hover.svg');
}

.product-page__button_type_minus {
  background-image: url('../../images/Minus.svg');
  width: 24px;
  height: 24px;
  transition: background-image 0.5s ease;
}

.product-page__button_type_minus:hover {
  background-image: url('../../images/minus-hover.svg');
}

.product-page__button_type_minus:disabled {
  background-image: url('../../images/minus-icon-disabled.svg');
}

.product-page__count {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #403f32;
  cursor: pointer;
  transition: color 0.5s ease;
}

.product-page__count:hover {
  color: #726e38;
}

.product-page__button_type_shopping-cart {
  font-family: 'Mulish';
  background-color: #a49e4a;
  border-radius: 12px;
  padding: 12px 20px;
  color: #fbfaeb;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  text-decoration: none;
  transition: all 0.5s ease;
}

.product-page__button_type_shopping-cart:hover {
  background-color: #726e38;
}

.product-page__container {
  display: flex;
  gap: 20px;
}
