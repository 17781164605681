.popup-with-filters__filters {
  position: relative;
  height: 100vh;
  padding: 52px 40px 48px;
  background-color: #fff;
}

.popup-with-filters__close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  background: transparent url('../../images/close-filters-popup.svg') 50% / 24px
    24px no-repeat;
}

.form-with-filters__title {
  color: #2a254b;
  font-family: 'Didact-Gothic';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.17;
  margin: 0 0 32px 0;
}

.filter {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 28px;
}

.filter:last-child {
  margin-bottom: 40px;
}

.filter__title {
  color: #21211b;
  font-family: 'Mulish';
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  margin: 0;
}

.filter-text {
  color: #21211b;
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.filter-range {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  row-gap: 20px;
}

.filter-range__item {
  position: relative;
  width: 153px;
  grid-row: 1/2;
}

.filter-range__item_style_min {
  grid-column: 1/2;
  justify-self: start;
}

.filter-range__item_style_max {
  grid-column: 2/3;
  justify-self: end;
}

.filter-range__item_style_min:before,
.filter-range__item_style_max:before {
  position: absolute;
  top: 10px;
  left: 0;
  width: auto;
}

.filter-range__item_style_min:before {
  content: 'от';
}

.filter-range__item_style_max:before {
  content: 'до';
}

.filter-range__item::after {
  content: '₽';
  position: absolute;
  top: 10px;
  right: 0;
  width: auto;
}

.filter-range__input {
  width: 102px;
  box-sizing: border-box;
  padding: 7px 20px;
  border-radius: 12px;
  border: 1px solid #a5a38f;
  background: #fff;
  display: block;
  margin-left: 29px;
}

.filter-range__input::placeholder {
  color: #cdcdc6;
}

.price-slider {
  position: relative;
  height: 16px;
  grid-column: 1/3;
  grid-row: 2/3;
  display: flex;
  align-items: center;
}

.price-slider:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #a49e4a;
}

.price-slider .thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: url('../../images/slider-blob.svg') 50% / 16px 16px no-repeat;
  cursor: pointer;
}

.price-slider__thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: url('../../images/slider-blob.svg') 50% / 16px 16px no-repeat;
  cursor: pointer;
}

.filters-form__buttons-section {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.filters-form__button {
  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  width: 186px;
  box-sizing: border-box;
  border: 0;
  border-radius: 12px;
  padding: 12px 10px;
  cursor: pointer;
  transition: background-color 0.6s;
}

.filters-form__button_style_submit {
  color: #fff;
  background-color: #a49e4a;
}

.filters-form__button_style_reset {
  color: #21211b;
  border: 1px solid #a49e4a;
  background-color: #fff;
}

.filters-form__button_style_submit:hover {
  background-color: #726e38;
}

.filters-form__button_style_submit:active {
  background-color: #726e38;
}

.filters-form__button_style_reset:hover {
  background-color: #e3e1b9;
}
