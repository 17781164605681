.accordion__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  background: rgba(255, 255, 255, 1);
  border-top: 1px solid #e9d7c1;
  padding: 21px 0 14px 0;
  transition: all 0.5s ease;
}

.accordion__items_type_product-page {
  padding: 0 0 28px 0;
  row-gap: 0px;
  border-top: none;
}

.accordion__items_active {
  padding: 24px 0;
  transition: all 0.5s ease;
}

.accordion__items_type_product-page_active {
  padding: 0 0 28px 0;
}

.accordion__item-panel {
  color: black;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.accordion__item-panel_show {
  height: auto;
  max-height: 250px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(1, 0, 1, 0);
}

.accordion__item {
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.accordion__item_type_product-page {
  padding-bottom: 8px;
  border-bottom: 1px solid #e9d7c1;
}

.accordion__item-title {
  font-family: 'Didact-Gothic', Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.167;
  margin: 0;
}

.accordion__item-title_type_product-page {
  font-family: 'Mulish';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #121212;
}

.accordion__item-content {
  font-family: 'Mulish', Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #21211b;
  line-height: 1.71;
  margin: 0;
}

.accordion__item-content_type_product-page {
  padding-top: 16px;
  font-family: 'Mulish';
  font-size: 16px;
  line-height: 24px;
}

.accordion__item-chevron {
  display: block;
  margin-right: 33px;
  width: 24px;
  height: 24px;
}

.accordion__item-chevron_type_product-page {
  margin: 0 0 0 auto;
}

.accordion__item-chevron_up {
  transform: rotate(180deg);
  transition: all 0.1s linear;
}
