.empty-cart {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
}

.empty-cart__text {
  color: #21211b;
  text-align: center;
  margin: 0 auto;
  font-family: Didact-Gothic, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.67;
}

.empty-cart__text_small {
  margin: 0 auto;
  font-family: Mulish, Arial, sans-serif;
  font-size: 16px;
  padding: 12px 0 0 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
