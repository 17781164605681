/* product-card */

.product-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto);
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #e9d7c1;
  max-width: 316px;
  min-height: 464px;
  max-height: 512px;
  box-sizing: border-box;
  flex-grow: 1;
  color: #121212;
}

.product-card__link {
  text-decoration: none;
  max-height: 268px;
  margin-bottom: 16px;
}

/* Ниже костыль, без которого невозможно проставить оступы
между карточками при использовании библиотеки react-slick */

/* product-card__card-container */

.product-card__container {
  position: relative;
  box-sizing: border-box;
}

/* product-card__image */

.product-card__image {
  max-width: 268px;
  max-height: 268px;
  object-fit: cover;
  border-radius: 12px;
  transition:
    transform 0.6s,
    opacity 0.6s;
}

.product-card__image:hover {
  opacity: 0.8;
  transform: scale(1.015);
}

/* product-card__like-button */

.product-card__like-button {
  position: absolute;
  z-index: 500;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  padding: 0;
  align-self: end;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.6s;
  background: url('../../images/favourites-card.svg') 50% / auto auto no-repeat;
}

.product-card__like-button:hover {
  background: url('../../images/favourites-card-hover.svg') 50% / auto auto
    no-repeat;
}

.product-card__like-button:hover ~ .product-card__link > .product-card__image {
  opacity: 0.8;
  transform: scale(1.015);
}

.product-card__like-button_liked {
  background: url('../../images/favourites-card-active.svg') 50% / auto auto
    no-repeat;
}

/* product-card__info-container */

.product-card__info-container {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.product-card__info-container {
  row-gap: 4px;
}

/* product-card__add-to-cart-button*/

.product-card__add-to-cart-button {
  width: 268px;
  height: 40px;
  background-color: #a49e4a;
  border: 0;
  border-radius: 12px;
  color: #fbfaeb;
  cursor: pointer;
  font-family: Mulish, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.33;
  font-size: 18px;
  display: flex;
  transition: background-color 0.6s;
  align-items: center;
  justify-content: center;
}

.product-card__add-to-cart-button:hover {
  background-color: #726e38;
}

.product-card__add-to-cart-button:active {
  background-color: #e3e1b9;
}

/* product-card__product-item */

.product-card__product-item {
  margin: 0;
}

.product-card__product-brand {
  font-size: 14px;
  color: #21211b;
  line-height: 1.71;
}

/* product-card__product-name */

.product-card__product-name {
  line-height: 1.5;
  font-weight: 600;
  color: #21211b;
  height: 48px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* product-card__product-price */

.product-card__product-price {
  font-family: 'Didact-Gothic', Arial, sans-serif;
  font-size: 18px;
  color: #21211b;
  line-height: 1.55;
  margin-bottom: 16px;
}

.product-card__switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #a49e4a;
  background: #fff;
  box-sizing: border-box;
  padding: 0 8px;
  width: 268px;
  height: 40px;
}

.product-card__switch-button {
  border: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

.product-card__switch-button:hover {
  opacity: 0.7;
}

.product-card__switch-button_minus {
  background: url('../../images/minus-icon.svg') 50% / 100% no-repeat;
  transition: background 0.6s;
}

.product-card__switch-button_plus {
  background: url('../../images/plus-icon.svg') 50% / 100% no-repeat;
  transition: background 0.6s;
}

.product-card__switch-button_minus:hover {
  background: url('../../images/minus-hover.svg') 50% / 100% no-repeat;
}

.product-card__switch-button_plus:hover {
  background: url('../../images/plus-hover.svg') 50% / 100% no-repeat;
}

.product-card__switch-counter {
  font-family: 'Mulish', Arial, sans-serif;
  font-weight: 400;
  color: #21211b;
  font-size: 18px;
  line-height: 1.71;
  cursor: default;
  margin: 0;
}
