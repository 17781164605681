.privacy-policy {
  font-family: 'Mulish', Arial, sans-serif;
  max-width: 780px;
}

.privacy-policy__main-list,
.privacy-policy__items,
.privacy-policy__inserted-unordered-items,
.privacy-policy__inserted-ordered-items {
  list-style: none;
  counter-reset: li;
  padding: 0;
}

.privacy-policy__main-item:before,
.privacy-policy__item:before,
.privacy-policy__inserted-ordered-item:before {
  counter-increment: li;
  content: counters(li, '.') '. ';
}

.privacy-policy__inserted-unordered-item:before {
  content: '— ';
}

.privacy-policy__main-list {
  padding: 0;
  margin: 0;
}

.privacy-policy__main-item {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 11px;
}

.privacy-policy__items {
  margin-top: 10px;
  padding: 0;
}

.privacy-policy__item {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
}
