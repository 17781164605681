.popup {
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.7);
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.5s ease,
    opacity 0.5s ease;
}

.popup_type_filters {
  background-color: rgba(18, 16, 16, 0.5);
}

.popup_active {
  visibility: visible;
  opacity: 1;
}

.popup__block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 356px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 32px;
}

.popup__block_type_confirm {
  padding: 32px;
}

.popup__text {
  margin: 0;
  text-align: center;
  color: grey;
  font-size: 14px;
  padding-bottom: 10px;
}

.popup__text_info {
  margin-bottom: 10px;
}

.popup__title {
  text-align: center;
  margin: 0;
  font-family: Didact-Gothic, Arial, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.5px;
}

.popup__title_type_info-popup {
  margin-top: 20px;
}

.popup__title_type_confirm {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  padding-bottom: 20px;
  text-align: center;
}

.popup__toggle {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: rgba(64, 63, 50, 1);
  margin: 0 auto 4px auto;
  font-family: Mulish, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.67;
  text-decoration: underline;
}

.popup__link {
  text-decoration: none;
  font-size: 12px;
  color: grey;
  margin: 0 auto 10px;
}

.popup__link_info {
  font-size: 14px;
  margin-top: 10px;
}

.popup__button {
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  padding: 0;
}

.popup__form {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
}

.popup__form_type_profile {
  margin-top: 20px;
  display: flex;
  width: 432px;
  flex-direction: column;
}

.popup__input {
  width: calc(100% - 20px);
  border: 1px solid rgba(233, 215, 193, 1);
  border-radius: 12px;
  padding: 4px 8px;
  margin-top: 4px;
  min-height: 24px;
  outline: 0;
}

.popup__input:focus:not(.popup__input_error) {
  border: 2px solid rgba(227, 225, 185, 1);
}

.popup__information {
  margin: 12px auto 0 auto;
  min-height: 24px;
  max-width: 280px;
  text-align: center;
  color: rgba(165, 163, 143, 1);
  font-family: Mulish, Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.popup__information-text {
  margin: 0 auto;
}

.popup__link_type_policy {
  display: block;
  font-size: 12px;
  color: rgba(64, 63, 50, 1);
  text-decoration: underline;
  margin: 0 auto;
}

.popup__button_type_submit {
  margin: 20px auto 0 auto;
  border-radius: 12px;
  padding: 12px 16px;
  width: 100%;
  min-height: 56px;
  font-family: Mulish, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid rgba(167, 167, 167, 1);
  background-color: rgba(164, 158, 74, 1);
  color: rgba(251, 250, 235, 1);
  transition: background-color 0.6s;
}

.popup__button_type_password {
  max-width: 176px;
  min-height: 48px;
  box-sizing: border-box;
}

.popup__button_type_return {
  margin-top: 0;
  max-width: 264px;
  min-height: 52px;
}

.popup__button_type_submit:hover {
  border: 1px solid rgba(114, 110, 56, 1);
  background-color: rgba(114, 110, 56, 1);
  color: rgba(251, 250, 235, 1);
}

.popup__button_type_submit:disabled {
  cursor: inherit;
  border: 1px solid rgba(205, 205, 198, 1);
  background-color: rgba(250, 250, 250, 1);
  color: rgba(205, 205, 198, 1);
}

.popup__button_type_login {
  max-width: 168px;
}

.popup__buttons {
  display: flex;
}

.popup__button_type_logout {
  min-width: 168px;
  min-height: 0;
  width: 0;
  margin-right: 24px;
  border: 1px solid #b74128;
  background-color: transparent;
  border-radius: 12px;
  padding: 11px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #b74128;
  transition: all 0.5s ease;
}

.popup__button_type_logout:hover {
  border: 1px solid #c64126;
  color: #c64126;
  background-color: transparent;
}

.popup__button_type_save {
  margin-top: 20px;
  min-width: 164px;
  max-height: 48px;
  border-radius: 12px;
  border: none;
  background-color: #a49e4a;
  float: left;
  color: #fbfaeb;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Mulish', Arial, sans-serif;
  transition: background-color 0.5s ease;
}

.popup__button_type_save:hover {
  background-color: #726e38;
}

.popup__button_type_close {
  border: none;
  background-color: transparent;
  position: relative;
  background-image: url('../../images/close.svg');
  width: 20px;
  height: 20px;
  top: 4px;
  left: 273px;
  transition: background-image 0.6s;
}

.popup__button_type_close:hover {
  background-image: url('../../images/close-hover.svg');
}

.popup__block_type_close-confirm {
  left: 338px;
  top: -5px;
}

.popup__button_type_close-password {
  left: 317px;
  top: -1px;
}

.popup__button_type_close-info {
  left: 164px;
  top: -125px;
}

.popup__error {
  color: rgba(183, 65, 40, 1);
  font-weight: 400;
  line-height: 0.9;
  min-height: 25px;
  padding-top: 4px;
  font-family: Mulish, Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
}

.popup__error_type_password {
  min-height: 13px;
}

.popup__input_error {
  border: 1px solid rgba(183, 65, 40, 1);
  color: rgba(183, 65, 40, 1);
}

.popup__server-error {
  display: flex;
  justify-content: center;
  color: rgba(183, 65, 40, 1);
  min-height: 20px;
  font-family: Mulish, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 2;
  margin-bottom: 4px;
}

.popup__server-error_type_password {
  margin-bottom: 20px;
}

.popup__label {
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
}

.popup__password {
  position: relative;
}

.popup__button_type_eye-not-show {
  position: absolute;
  top: 25%;
  right: 10px;
  width: 24px;
  height: 24px;
  background-image: url('../../images/eye-not-show.svg');
  background-color: transparent;
  background-size: contain;
  border: none;
  transition: background-image 0.3s;
}

.popup__button_type_eye-show {
  background-image: url('../../images/eye-show.svg');
}
