.promo {
  max-width: 1360px;
  margin: 0 auto;
  padding: 40px 40px 0 40px;
}

.promo-brands {
  display: flex;
}

.promo-brands__photo {
  width: 664px;
  height: 442px;
  object-fit: cover;
  border-radius: 12px;
}

.promo-brands__container {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(50% - 60px);
}

.promo-brands__container-title {
  color: #403f32;
  margin: 0;
  font-family: Didact-Gothic, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.17;
}

.promo-brands__container-slogan {
  color: #21211b;
  margin: 0;
  padding-top: 12px;
  font-family: Didact-Gothic, Arial, sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.11;
  letter-spacing: -1px;
}

.promo-brands__container-description {
  color: #403f32;
  margin: 0;
  padding-top: 25px;
  font-family: Mulish, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.33;
}

.promo-brands__container-button {
  margin-top: 32px;
}
