.button-right {
  display: flex;
  gap: 12px;
  padding: 12px 16px 12px 16px;
  max-width: fit-content;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(114, 110, 56, 1);
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.6s;
}

.button-right__text {
  margin: 0;
  font-family: Mulish, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(33, 33, 27, 1);
}

.button-right__arrow {
  background-image: url('../../images/button_right-arrow.svg');
  width: 24px;
  height: 24px;
}

.button-right:hover {
  background-color: rgba(227, 225, 185, 1);
}

.button-right:hover .button-right__text {
  color: rgba(114, 110, 56, 1);
}

.button-right:hover .button-right__arrow {
  background-image: url('../../images/button-right-arrow-hover.svg');
  width: 24px;
  height: 24px;
}
