.navigation-link {
  text-decoration: none;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  background-color: inherit;
  border: none;
  cursor: pointer;
  color: #000;
}

.navigation-link__image {
  width: 24px;
  height: 24px;
}

.navigation-link__count_active {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Didact-Gothic', Arial, sans-serif;
  position: absolute;
  border-radius: 100px;
  width: 22px;
  height: 16px;
  background-color: #726e38;
  color: rgba(251, 250, 235, 1);
  top: -3px;
  right: calc(50% - 27px);
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}

.navigation-link__text {
  font-family: 'Mulish', Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  color: rgba(64, 63, 50, 1);
}
