.shopping-cart {
  display: grid;
  grid-template-columns: minmax(auto, 1012px) auto;
  grid-template-rows: repeat(3, auto);
  gap: 32px;
  padding: 40px;
  box-sizing: border-box;
}

.shopping-cart_style_empty {
  grid-template-columns: auto;
  grid-template-rows: repeat(2, auto);
  row-gap: 100px;
}

.shopping-cart__title {
  font-family: 'Didact-Gothic', Arial, sans-serif;
  color: #21211b;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.5px;
  margin: 0;
  grid-column: 1/2;
  grid-row: 1/2;
}

.shopping-cart__products-block {
  align-self: start;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  grid-column: 1/2;
  grid-row: 2/4;
  padding: 0;
  margin-right: 32px;
}

.shopping-cart__products-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 0;
  padding: 0;
}

.shopping-cart__place-order-block {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #e9d7c1;
  grid-column: 2/3;
  grid-row: 2/3;
}

.shopping-cart__total-block {
  display: flex;
  justify-content: space-between;
}

.shopping-cart__total-block-item {
  font-family: 'Mulish', Arial, sans-serif;
  color: #21211b;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  margin: 0;
}

.shopping-cart__place-order-button {
  font-family: 'Mulish', Arial, sans-serif;
  color: #fbfaeb;
  text-decoration: none;
  border-radius: 12px;
  background: #a49e4a;
  text-align: center;
  font-size: 24px;
  line-height: 1.33;
  padding: 12px 16px;
  border: 1px solid rgba(167, 167, 167, 1);
  transition: background-color 0.6s;
}

.shopping-cart__place-order-button:hover {
  border: 1px solid rgba(114, 110, 56, 1);
  background-color: rgba(114, 110, 56, 1);
  color: rgba(251, 250, 235, 1);
}
