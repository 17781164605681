.catalog {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 20px 40px 100px;
}

.catalog .breadcrumbs {
  padding: 0;
}

.text {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
}
